import './layout.css'
import React from 'react'
import Layout from '../components/layout'

class FAQ extends React.Component {
	render() {
		return (
			<Layout>
				<h2 className="faq-title has-text-centered title is-3">Frequently Asked Questions</h2>
				<div className="faq-content">
					<h3>How do I know if I’ve received any mutual matches?</h3>
					<p className="small-space">To see if you’ve received any mutual matches, click on the ‘Result’ button on the Events page or go to the menu and select the My Matches page.</p>
					<p>As everyone will be selecting their matches at different times, you may not see any
					matches right away. Your final match results will be shown on the My Matches page 48hrs after the end time of the event.</p>
					<div className="space"></div>
					<h3>What if I don’t see any mutual matches?</h3>
					<p className="small-space">If you don’t see any mutual matches on the My Matches page 48hrs after the end time of the event, this means you haven’t received any mutual matches.</p>
					<p>Please don’t be disheartened if you have not received any mutual matches as
					sometimes it can take time to find someone you have a connection with. We do have
					mostly different people attending each of our events which means there are lots more potential matches for you to meet!</p>
					<div className="space"></div>
					<h3>What information is shown to my mutual matches?</h3>
					<p className="small-space">Only your preferred contact details and photo will be shared with any mutual matches you receive.</p>
					<p>You can choose to have either your mobile number, email or both shown to any mutual
					matches you receive. These details can also be updated at any time on the My Profile page.</p>
					<div className="space"></div>
					<h3>Why isn’t everyone from the event I attended shown in the matching list?</h3>
					<p>As our matching platform is optional for guests to use at all events except Speed Dating, some guests from the event you attended may not be in the matching list.</p>
					<div className="space"></div>
					<h3>Will other attendees know if I have selected them?</h3>
					<p>No, only people you have mutually matched with will know that you have selected them.</p>
					<div className="space"></div>
					<h3>Do I need a smart phone to attend an event?</h3>
					<p>No, our matching platform is designed to be used after attending an event as we want you to enjoy the event without being distracted by your mobile phone.</p>
					<div className="space"></div>
					<h3>Can I select my matches on either a mobile device or desktop?</h3>
					<p>Yes, our site works perfectly on any mobile or desktop web browser.</p>
					<div className="space"></div>
					<h3>What happens if I don’t push the ‘Done Matching’ button?</h3>
					<p>If you don’t push the ‘Done Matching’ button your matches won’t be recorded. Only push this button once all your matches have been selected.</p>
					<div className="space"></div>
					<h3>How can I check or change my profile photo?</h3>
					<p>You can check or change your photo at any time on the My Profile page. To change
					your photo, click on the ‘Change Photo’ button below your current photo. You&#39;ll be able
					to upload a shot from your photos or take one via the camera. When you are done click
					on the ‘Save Changes’ button.</p>
					<div className="space"></div>
					<h3>How do I delete my account?</h3>
					<p>You can delete your account and all your matching data by selecting the ‘Delete
					Account’ button at the bottom of the My Profile page. Once you select this button your
					profile and matching data will be permanently deleted.</p>
					<div className="space"></div>
					<h3>Still have more questions?</h3>
					<p className="small-space">Email us at <a href="mailto:info@encounterdating.com.au">info@encounterdating.com.au</a></p>
					<p className="small-space">Text or call us on <a href="tel:0444555857" className="has-text-dark">0444 555 857</a></p>
				</div>
			</Layout>
		)
	}
}

export default FAQ;